import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Sample Text
        </p>

        <div>
          <button className='thumb-button thumbs-up'>
          </button>
          <button className='thumb-button thumbs-down'>
          </button>
          
        </div>

      </header>
    </div>
  );
}

export default App;
